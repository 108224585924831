import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;

const companyUrl = store.state.apiUrl+'company/';

export default {
  createNewCompany(name) {
    return axios({
      method: 'post',
      url: companyUrl + 'create',
      data: {
        name: name
      }
    });
  },
  updateCompanyName(companyName) {
    return axios({
      method: 'post',
      url: companyUrl + 'update-name',
      data: { name: companyName }
    });
  },
  updateCompany(companyData) {
    return axios({
      method: 'post',
      url: companyUrl + 'update',
      data: companyData
    });
  },
  updateCompanyAccentColor(color) {
    return axios({
      method: 'post',
      url: companyUrl + 'update',
      data: {
        color: color
      }
    });
  },
  updateCompanyTextAccentColor(color) {
    return axios({
      method: 'post',
      url: companyUrl + 'update',
      data: {
        color: color
      }
    });
  },
}
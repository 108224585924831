<template>

  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="h-screen flex overflow-hidden"
    :class="currentRouteMX == 'clients/new-invoice' ? 'bg-il-dark' : 'bg-il-softgrey'"
  >
    <Nav />

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="p-2 bg-il-purple flex justify-between items-center">
        <div class="flex items-center">
          <button
            @click="toggleVisible"
            class="bg-white -ml-0.5 -mt-0.5 h-10 w-20 inline-flex items-center justify-center rounded-md outline-none"
          >
            <i class="fas fa-bars text-xs"></i>
            <span class="ml-1 uppercase text-sm">
              Menu
            </span>
          </button>
          <div
            @click="navigateToMX('home')"
            class="text-2xl text-white ml-4 cursor-pointer"
          >
            <i class="fas fa-flask"></i> Invoice Labs
          </div>
        </div>

        <div
          v-if="getAuthStatusX"
          class="items-center px-4 hidden sm:flex"
        >
          <div
            @click="navigateToMX('company-dashboard')"
            class="bg-il-teal text-white w-10 h-10 rounded-full overflow-hidden justify-center items-center flex font-bold text-2xl border-2 border-white cursor-pointer"
            :title="getUserCurrentCompanyX.name"
          >
            <div
              v-if="!getUserCurrentCompanyX.logo_url"
            >
              {{ getUserCurrentCompanyX.name.charAt(0) }}
            </div>
            <div v-else>
              <img
                class="w-10 h-10 object-cover"
                :src="getUserCurrentCompanyX.logo_url"
                :alt="getUserCurrentCompanyX.name"
              >
            </div>
          </div>
          <div
            @click="navigateToMX('profile')"
            class="ml-4 text-white font-bold cursor-pointer"
          >
            {{ getUserX.name }}
            <span
              v-if="getIsOnTrialX || getIsSubscribedX"
              v-tippy="{ content: 'Premium Subscription ' + (getIsOnTrialX ? '(Trial)' : '') }"
              class="text-yellow-400 text-sm inline-block ml-1"
            >
              <i class="fas fa-star"></i>
            </span>
          </div>
        </div>
      </div>

      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
        <div class="flex flex-grow">
          <router-view v-slot="slotProps">
            <transition enter-active-class="animate__animated animate__faster animate__fadeIn" leave-active-class="animate__animated animate__faster animate__fadeOut" name="route" mode="out-in">
              <component :is="slotProps.Component"></component>
            </transition>
          </router-view>
        </div>
      </main>

    </div>
  </div>
</template>

<script>

import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import { mapGetters, mapMutations } from 'vuex';
import { directive } from 'vue-tippy';

export default {
  directives: {
    'v-tippy': directive,
  },
  components: {
    Header,
    Nav,
    Footer
  },
  data() {
    return {
      menu: true
    }
  },
  mounted() {

  },
  methods: {
    ...mapMutations([
      'setMenuVisibleX',
      'setMenuOverlayVisibleX'
    ]),
    toggleVisible() {
      this.setMenuVisibleX(!this.getMenuVisibleX);
    }
  },
  computed: {
    ...mapGetters([
      'getAuthStatusX',
      'getAppLoadedX',
      'getMenuVisibleX',
      'getUserX',
      'getUserCurrentCompanyX',
      'getIsOnTrialX',
      'getIsSubscribedX'
    ]),
  },
  watch: {
    getMenuVisibleX: function (val) {
      if(val == false) {
        setTimeout(() => {
          this.setMenuOverlayVisibleX(false);
        }, 200);
      } else {
        this.setMenuOverlayVisibleX(true);
      }
    }
  }
}
</script>



import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;

const userUrl = store.state.apiUrl+'user/';

export default {
  updateAccountInfo() {

  },
  switchCompany(companyId) {
    return axios({
      method: 'post',
      url: userUrl + 'switch_company',
      data: {
        company_id : companyId
      }
    });
  },
  getPaymentIntent() {
    return axios({
      method: 'get',
      url: userUrl + 'subscription/payment-intent'
    });
  },
  startTrial() {
    return axios({
      method: 'post',
      url: userUrl + 'subscription/start-trial'
    });
  },
  cancelTrial() {
    return axios({
      method: 'post',
      url: userUrl + 'subscription/cancel-trial'
    });
  },
  savePaymentMethod(paymentMethod) {
    return axios({
      method: 'post',
      url: userUrl + 'subscription/payment-method/save',
      data: {
        payment_method : paymentMethod
      }
    });
  },
  deletePaymentMethod(paymentMethod) {
    return axios({
      method: 'post',
      url: userUrl + 'subscription/payment-method/delete'
    });
  },
  cancelSubscription(selectedCompanyId, selectedClientIds) {
    return axios({
      method: 'post',
      url: userUrl + 'subscription/cancel',
      data: {
        company_id : selectedCompanyId,
        client_ids: selectedClientIds
      }
    });
  },
  startSubscription() {
    return axios({
      method: 'post',
      url: userUrl + 'subscription/create'
    });
  },
  resumeSubscription() {
    return axios({
      method: 'post',
      url: userUrl + 'subscription/resume'
    });
  }
}
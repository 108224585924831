<template>

  <div class="flex p-3 text-white text-2xl w-full bg-il-purple shadow-md z-10">
    <div class="mr-4">
      <i class="fas fa-flask"></i>
      <!-- <img class="w-45px" src="../../assets/logo.jpg" /> -->
    </div>
    <div>
      Invoice Labs
    </div>

  </div>

</template>

<script>

export default {
  methods: {
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    }
  }
}
</script>
<template>
  <div
    class="flex flex-col"
  >
    <div
      class="transition-all duration-200 ease-in-out bg-white overflow-hidden"
      :class="companiesListVisible ? 'h-150px' : 'h-0'"
    >
      <div
        v-if="!showNewCompany"
        class="text-il-teal font-bold text-center p-2 border-b border-il-dark cursor-pointer hover:bg-il-teal hover:text-white transition-all duration-200 ease-in-out"
        @click="toggleShowNewCompany"
      >
        + New Company
      </div>

      <div
        v-else
        class="flex items-center"
      >
        <input
          type="text"
          class="flex-grow p-2 outline-none border-b border-il-dark"
          placeholder="New Company Name"
          v-model="newCompanyName"
        >

        <div
          v-if="!createLoading"
          class="flex"
        >
          <div
            class="bg-il-teal-faded hover:bg-il-teal p-2 text-center text-white text-xl transition-all duration-200 ease-in-out cursor-pointer"
            @click="createCompany"
          >
            <i class="fas fa-check-circle"></i>
          </div>
          <div
            class="bg-red-400 hover:bg-red-600 p-2 text-center text-white text-xl transition-all duration-200 ease-in-out cursor-pointer"
            @click="toggleShowNewCompany"
          >
            <i class="fas fa-times-circle"></i>
          </div>
        </div>

        <div
          v-else
          class="bg-il-teal text-white border-b border-il-dark p-2 px-4"
        >
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>

      </div>

      <div class="overflow-y-scroll h-24">
        <div
          v-for="(company) in switchableCompanies"
          :key="company.id"
          class="border-b border-black p-2 py-1 cursor-pointer hover:bg-il-teal-faded transition-all duration-200 ease-in-out"
          @click="switchCompany(company.id)"
        >
          {{ company.name }}
        </div>
      </div>
    </div>

    <div
      @click="toggleCompaniesListVisible"
      class="p-2 text-white flex justify-between border-b border-t border-white bg-il-dark cursor-pointer"
    >
      <div class="text-center flex-grow">
        {{ getUserCurrentCompanyX.name }}
      </div>
      <div v-if="companiesListVisible">
        <span v-if="switchLoading">
          <i class="fas fa-circle-notch fa-spin"></i>
        </span>
        <span v-else>
          <i class="fas fa-chevron-up"></i>
        </span>
      </div>
      <div v-else>
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters, mapMutations } from 'vuex';
  import companyRequests from '../../helpers/companyRequests';
  import userRequests from '../../helpers/userRequests';

  export default {
    data() {
      return {
        companiesListVisible: false,
        showNewCompany: false,
        newCompanyName: null,
        createLoading: false,
        switchLoading: false
      }
    },
    methods: {
      ...mapMutations([
        'setUserCurrentCompanyIdX',
        'loginUserX',
        'increaseCompanySwitchKeyX',
        'setMenuVisibleX'
      ]),
      toggleCompaniesListVisible() {
        if(this.companiesListVisible) {
          this.showNewCompany = false;
          this.newCompanyName = null;
        }
        this.companiesListVisible = !this.companiesListVisible;
      },
      toggleShowNewCompany() {
        if(this.getUserX.emailVerifiedAt) {
          if(this.getIsOnTrialX || this.getIsSubscribedX) { // is on trial or subscribed
            if(this.getUserCompaniesX.length < 3) { // allowed to create new company
              this.showNewCompany = !this.showNewCompany;
            } else { // Maximum company amount already
              this.popErrorMX("A maximum of 3 Companies allowed");
            }
          } else { // is not on trial or subscribed
            this.popErrorMX("Subscribe to Premium Plan for more companies");
          }
        } else { // email not verified
          this.companiesListVisible = false;
          this.setMenuVisibleX(false);
          this.$router.push({ name: 'profile' });
        }
      },
      createCompany() {
        if(!this.createLoading) {
          this.createLoading = true;

          companyRequests.createNewCompany(this.newCompanyName)
          .then((response) => {
            this.loginUserX(response.data.user);
            this.companiesListVisible = false;
            this.showNewCompany = false;
            this.newCompanyName = null;
            this.switchCompany(response.data.user.current_company_id);
            // this.setUserCurrentCompanyIdX(response.data.user.current_company_id);
            this.createLoading = false;
          }).catch(error => {
            // console.log(error.response.data);
            this.createLoading = false;
          });
        }
      },
      switchCompany(companyId) {
        if(!this.switchLoading) {
          this.switchLoading = true;
          var that = this;
          userRequests.switchCompany(companyId)
          .then(function() {
            that.companiesListVisible = false;
            that.setUserCurrentCompanyIdX(companyId);
            that.increaseCompanySwitchKeyX();
            that.switchLoading = false;
            if(that.$route.name.startsWith('clients/')) {
              that.$router.push({ name: 'clients' });
            }
          })
          .catch(error => {
            // console.log(error);
            that.switchLoading = false;
          });
        }
      }
    },
    computed: {
      ...mapGetters([
        'getUserCompaniesX',
        'getUserCurrentCompanyX',
        'getUserX',
        'getIsOnTrialX',
        'getIsSubscribedX'
      ]),
      switchableCompanies() {
        const company = this.getUserCurrentCompanyX;
        return lodash.filter(this.getUserCompaniesX, function(c) {
          return c.id != company.id;
        });
      }
    }
  }
</script>
<template>

  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div>
    <div
      class="fixed inset-0 flex"
      :class="getMenuOverlayVisibleX ? 'z-40' : '-z-10'"
    >

      <div
        class="fixed inset-0 transition-opacity ease-linear duration-200"
        :class="getMenuVisibleX ? 'opacity-100' : 'opacity-0'"
      >
        <div
          @click="toggleMenuVisible"
          class="absolute inset-0 bg-il-purple opacity-75"
        >
        </div>
      </div>


      <div
        class="relative flex-1 flex flex-col max-w-xs w-full bg-il-dark transition ease-in-out duration-200 transform"
        :class="getMenuVisibleX ? 'translate-x-0' : '-translate-x-full'"
      >
        <div
          v-if="getMenuVisibleX"
          @click="toggleMenuVisible" class="absolute top-0 right-0 -mr-12 pt-2"
        >
          <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Close sidebar</span>
            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div class="flex-1 h-0 pb-4 overflow-y-auto">
          <div class="flex-shrink-0 flex items-center justify-center px-4 text-white text-2xl bg-il-dark pt-3 pb-0">
            <i class="fas fa-flask"></i>
            <span class="ml-2">
              Invoice Labs
            </span>
          </div>

          <div class="pb-3 flex justify-center">
            <a href="https://www.facebook.com/invoicelabs" class="text-xl text-white hover:text-il-fb transition-all duration-200 ease-in-out mx-2" target="_blank">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="https://twitter.com/InvoiceLabs" class="text-xl text-white hover:text-il-twitter transition-all duration-200 ease-in-out mx-2" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="https://www.linkedin.com/company/black-flame-digital-ltd" class="text-xl text-white hover:text-il-linkedin transition-all duration-200 ease-in-out mx-2" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>

          <nav class="mt-5">
            <CompanySwitch
              v-if="getAuthStatusX"
            />

            <div
              v-if="getAuthStatusX"
              @click="navigateTo('profile')"
              class="flex items-center justify-between text-xl p-2 transition-all duration-200 ease-in-out"
              :class="currentRouteMX.startsWith('profile') ? 'bg-white text-il-dark' : 'text-white hover:bg-white hover:text-il-dark cursor-pointer'"
            >
              <div class="text-center">
                My Profile
              </div>
              <span class="pr-1">
                <i class="fas fa-user"></i>
              </span>
            </div>

            <div
              @click="navigateTo('home')"
              class="flex items-center justify-between text-xl p-2 transition-all duration-200 ease-in-out"
              :class="currentRouteMX == 'home' ? 'bg-white text-il-dark' : 'text-white hover:bg-white hover:text-il-dark cursor-pointer'"
            >
              <span>
                Home
              </span>
              <span>
                <i class="fas fa-home"></i>
              </span>
            </div>

            <div
              @click="navigateTo('quick-invoice')"
              class="flex items-center justify-between text-xl p-2 transition-all duration-200 ease-in-out"
              :class="currentRouteMX == 'quick-invoice' ? 'bg-white text-il-dark' : 'text-white hover:bg-white hover:text-il-dark cursor-pointer'"
            >
              <span>
                Quick Invoice
              </span>
              <span class="pr-1">
                <i class="fas fa-file-invoice"></i>
              </span>
            </div>

            <div
              v-if="getAuthStatusX"
              @click="navigateTo('company-dashboard')"
              class="flex items-center justify-between text-xl p-2 transition-all duration-200 ease-in-out"
              :class="currentRouteMX.startsWith('company-') ? 'bg-white text-il-dark' : 'text-white hover:bg-white hover:text-il-dark cursor-pointer'"
            >
              <span>
                Company
              </span>
              <span class="pr-1">
                <i class="fas fa-building"></i>
              </span>
            </div>

            <div
              v-if="getAuthStatusX"
              @click="navigateTo('clients')"
              class="flex items-center justify-between text-xl p-2 transition-all duration-200 ease-in-out"
              :class="currentRouteMX.startsWith('clients') ? 'bg-white text-il-dark' : 'text-white hover:bg-white hover:text-il-dark cursor-pointer'"
            >
              <span>
                Clients
              </span>
              <span class="">
                <i class="fas fa-users"></i>
              </span>
            </div>

          </nav>
        </div>

        <div class="flex-shrink-0 flex">
          <div class="flex flex-col w-full">

            <div
              v-if="!getAuthStatusX"
              @click="navigateTo('login')"
              class="flex items-center justify-between text-xl p-2 transition-all duration-200 ease-in-out"
              :class="currentRouteMX == 'login' ? 'bg-white text-il-dark' : 'text-white hover:bg-white hover:text-il-dark cursor-pointer'"
            >
              <span>
                Login
              </span>
              <span class="pr-1">
                <i class="fas fa-sign-in-alt"></i>
              </span>
            </div>

            <div
              v-if="!getAuthStatusX"
              @click="navigateTo('register')"
              class="flex items-center justify-between text-xl p-2 transition-all duration-200 ease-in-out"
              :class="currentRouteMX == 'register' ? 'bg-white text-il-dark' : 'text-white hover:bg-white hover:text-il-dark cursor-pointer'"
            >
              <span>
                Register
              </span>
              <span>
                <i class="fas fa-user-plus"></i>
              </span>
            </div>

            <div
              v-if="getAuthStatusX"
              @click="logout"
              class="flex text-xl p-2 bg-red-600 text-white"
            >
              <div
                class="flex w-full items-center justify-between"
                :class="logoutLoading ? '' : 'cursor-pointer'"
              >
                <div>
                  Logout
                </div>

                <div v-if="!logoutLoading">
                  <i class="fas fa-power-off"></i>
                </div>
                <div v-else>
                  <i class="fas fa-circle-notch fa-spin"></i>
                </div>
              </div>
            </div>

            <Footer />

          </div>


        </div>
      </div>

      <div class="flex-shrink-0 w-14">
      <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>

</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import authRequests from '../../helpers/authRequests';
  import CompanySwitch from '../Nav/CompanySwitch';
  import Footer from './Footer';

  export default {
    data() {
      return {
        logoutLoading: false
      }
    },
    components: {
      CompanySwitch,
      Footer
    },
    methods: {
      ...mapMutations([
        'setMenuVisibleX',
        'logoutUserX'
      ]),
      navigateTo(name) {
        this.setMenuVisibleX(false);
        this.$router.push({ name: name });
      },
      toggleMenuVisible() {
        this.setMenuVisibleX(!this.getMenuVisibleX);
      },
      logout() {
        if(!this.logoutLoading) {
          this.logoutLoading = true;
          authRequests.logout()
          .then((response) => {
            this.logoutUserX();
            this.logoutLoading = false;
            this.$router.push({ name: 'home' })
          }).catch(error => {
            // console.log('Could not Log Out');
            // console.log(error);
          });
        }
      }
    },
    computed: {
      ...mapGetters([
        'getAuthStatusX',
        'getMenuVisibleX',
        'getMenuOverlayVisibleX',
        'getUserX',
      ])
    }
  }
</script>
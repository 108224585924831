<template>
  <hr class="mt-2 border-dashed border-white" />

  <div class="flex flex-col items-center text-sm mt-2 text-gray-400">
    <div
      @click="$parent.navigateTo('privacy')"
      class="cursor-pointer underline"
    >
      Privacy Policy
    </div>
    <div
      @click="$parent.navigateTo('terms')"
      class="cursor-pointer underline"
    >
      Terms and Conditions
    </div>
  </div>


  <div class="flex justify-center items-center text-gray-400 text-xs uppercase mt-1">
    &copy; 2020-{{ thisYear }}
    Invoice Labs @ {{ version }}
  </div>

  <div class="flex justify-center text-xs">
    <div class="uppercase py-1 text-gray-400">
      A project by <a href="https://blackflamedigital.com" class="font-bold" target="_blank">Black <span class="text-bfd-orange">Flame</span> Digital</a>
    </div>
  </div>

</template>

<script>

  import { format } from 'date-fns';

  export default {
    data() {
      return {

      }
    },
    computed: {
      thisYear() {
        return format(new Date(), 'yyyy');
      },
      version() {
        return this.$store.state.version;
      }
    }
  }
</script>